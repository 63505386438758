import React from "react";
import Products from "../components/products/Products";
import DATA from "../helpers/products.json";

const collections = {
  saxos: {
    title: "SAXOPHONES",
    titleEs: "SAXOFONES",
    data: DATA.filter((el) => el.category === "saxofones"),
  },
  trumpets: {
    title: "TRUMPETS",
    titleEs: "TROMPETAS",
    data: DATA.filter((el) => el.category === "trompeta"),
  },
  clarinets: {
    title: "CLARINETS",
    titleEs: "CLARINETES",
    data: DATA.filter((el) => el.category === "clarinetes"),
  },
  violin: {
    title: "VIOLINS",
    titleEs: "VIOLIN",
    data: DATA.filter((el) => el.category === "violin"),
  },
  flute: {
    title: "FLUTES",
    titleEs: "FLAUTAS",
    data: DATA.filter((el) => el.category === "flautas"),
  },
};

const ProductsPage = () => {
  return (
    <div>
      <Products data={collections} />
    </div>
  );
};

export default ProductsPage;
