import React from "react";
import ListProducts from "../listProducts/ListProducts";

import "./products.css";

const Products = ({ data }) => {
  const { saxos, trumpets, flute, clarinets, violin } = data;
  return (
    <section className="products">
      <ListProducts info={saxos} />
      <ListProducts info={trumpets} />
      <ListProducts info={flute} />
      <ListProducts info={clarinets} />
      <ListProducts info={violin} />
    </section>
  );
};

export default Products;
