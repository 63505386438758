import React from "react";
import { FormattedMessage } from "react-intl";

import "./distributors.css";

const Distributors = () => {
  return (
    <section className="distributors">
      <div className="distributors__container">
        <h2 className="d__title">
          <FormattedMessage id="dis.title" defaultMessage="Distributors" />
        </h2>
        <div className="d__list">
          <a href="http://www.musicclub.com.mx/" target="blank">
            <img
              src="https://i.ibb.co/ctHnjYd/LOGO-MUSICCLUB.png"
              alt="logo music club"
              className="dl__image"
            />
          </a>
          <a href="https://topmusic.com.mx/" target="blank">
            <img
              src="https://i.ibb.co/FYsBV4C/LOGO-TOPMUSIC.png"
              alt="logo top music"
              className="dl__image"
            />
          </a>
          <a href="https://www.amazon.com.mx/" target="blank">
            <img
              src="https://i.ibb.co/TLkjGJz/LOGO-AMAZON.png"
              alt="logo amazon"
              className="dl__image"
            />
          </a>
          <a href="https://hermesmusicclub.mx/" target="blank">
            <img
              src="https://i.ibb.co/tcvcL0d/LOGO-HMC.png"
              alt="logo hermes music club"
              className="dl__image"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Distributors;
