import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { langContext } from "../../context/langContext";
import ProductImages from "../productImages/ProductImages";

import "./product.css";

const Product = ({ data }) => {
  const { locale } = useContext(langContext);
  const { imgBG, model, rutas, esLang, usLang } = data;

  const lang = locale === "en-US" ? usLang : esLang;
  return (
    <section className="product">
      <div>
        <img src={imgBG} alt="" />
      </div>
      <h2 className="product__title">{model}</h2>
      <div className="product__content">
        <ProductImages data={rutas} />
        <div className="product__description">
          <p className="pd__text">{lang.description}</p>
          <span className="pd__title">
            <FormattedMessage
              id="product.features"
              defaultMessage="Features :"
            />
          </span>
          <ul>
            {lang.features.map((el, index) => (
              <li key={index} className="pd__listItem">
                {el}
              </li>
            ))}
          </ul>
        </div>
        <div className="product__dealers">
          <h3 className="pde__title">
            <FormattedMessage
              id="product.dealer"
              defaultMessage="Choose a dealer"
            />
          </h3>
          <div className="dealers__items">
            <a href="https://hermesmusicclub.mx/" target="blank">
              <img
                className="dealer__img"
                src="https://i.ibb.co/tcvcL0d/LOGO-HMC.png"
                alt="LOGO-HMC"
              />
            </a>
            <a href="http://www.musicclub.com.mx/" target="blank">
              <img
                className="dealer__img"
                src="https://i.ibb.co/ctHnjYd/LOGO-MUSICCLUB.png"
                alt="LOGO-MUSICCLUB"
              />
            </a>
            <a href="https://www.amazon.com.mx/" target="blank">
              <img
                className="dealer__img"
                src="https://i.ibb.co/TLkjGJz/LOGO-AMAZON.png"
                alt="LOGO-AMAZON"
              />
            </a>
            <a href="https://topmusic.com.mx/" target="blank">
              <img
                className="dealer__img"
                src="https://i.ibb.co/FYsBV4C/LOGO-TOPMUSIC.png"
                alt="LOGO-TOPMUSIC"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Product;
