import React from "react";
import { Link } from "react-router-dom";
import LOGO from "../../assets/img/albertLogo.png";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";

import "./footer.css";
import { FormattedMessage } from "react-intl";

const Footer = () => {
  return (
    <section className="footer">
      <div className="footer__main">
        <div>
          <img className="footer__img" src={LOGO} alt="" />
        </div>
        <div className="footer__links">
          <div>
            <span className="fl__title">
              <FormattedMessage id="footer.top" defaultMessage="Top producs" />
            </span>
            <ul>
              <li className="fl__item">
                <Link to="/ALBSAXA-L">ALBSAXA-L</Link>
              </li>
              <li className="fl__item">
                <Link to="ALBTR-L">ALBTR-L</Link>
              </li>
              <li className="fl__item">
                <Link to="ALBPICCOLO">ALBPICCOLO</Link>
              </li>
              <li className="fl__item">
                <Link to="AVSW44">AVSW44</Link>
              </li>
            </ul>
          </div>
          <div className="footer__socials">
            <span className="fl__title">
              <FormattedMessage id="footer.socials" defaultMessage="Socials" />
            </span>
            <div className="footer__btns">
              <div className="fb__btn">
                <AiFillFacebook />
              </div>
              <div className="fb__btn">
                <AiFillInstagram />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copy">
        <p>Copyright 2022, Albert, S.A. de C.V.</p>
      </div>
    </section>
  );
};

export default Footer;
