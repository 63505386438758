import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { langContext } from "../../context/langContext";

import "./productCard.css";

const ProductCard = ({ data }) => {
  const { locale } = useContext(langContext);
  const { imgBG, model, esLang, usLang } = data;

  const lang = locale === "en-US" ? usLang : esLang;

  return (
    <article className="card">
      <img className="card__img" src={imgBG} alt="" />
      <h3 className="card__title">{model}</h3>
      <span className="card__description">{lang.small__description}</span>
      <Link to={`/${model}`} className="card__btn">
        See
      </Link>
    </article>
  );
};

export default ProductCard;
