import React from "react";
import { useParams } from "react-router-dom";
import Product from "../components/product/Product";
import DATA from "../helpers/products.json";

const ProductPage = () => {
  const { product } = useParams();
  const productFind = DATA.find((el) => el.model === product);

  if (!productFind) {
    return <div></div>;
  }
  return (
    <div>
      <Product data={productFind} />
    </div>
  );
};

export default ProductPage;
