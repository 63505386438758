import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import Slider from "../slider/Slider";
import { langContext } from "../../context/langContext";

import "./collection.css";

const Collection = ({ data }) => {
  const { locale } = useContext(langContext);
  const { position, img, esLang, usLang, dataFil } = data;
  const [seeSlider, setSeeSlider] = useState(false);

  const lang = locale === "en-US" ? usLang : esLang;

  return (
    <section>
      <div className={`collection ${position === "right" ? "right" : ""}`}>
        <div className="collecion__image">
          <img className="ci__img" src={img} alt="" />
          <div className="ci__textCont">
            <h3>{lang.title}</h3>
          </div>
        </div>
        <div className="collection__text">
          <p>{lang.description}</p>
          <button className="ct__btn" onClick={() => setSeeSlider(true)}>
            <FormattedMessage
              id="collection.btn"
              defaultMessage="See collection"
            />
          </button>
        </div>
      </div>
      <Slider
        seeSlider={seeSlider}
        setSeeSlider={setSeeSlider}
        closeMenu={true}
        data={dataFil}
      />
    </section>
  );
};

export default Collection;
