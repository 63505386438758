import DATA from "./products.json";

export const collections = [
  {
    esLang: {
      title: "Saxofón",
      description:
        "Instrumento musical cónico, de la familia de los instrumentos de viento-madera, hecho de latón, que consta de una boquilla con una caña simple. Inventado a principios de los años 40. El saxofón se asocia comúnmente con la música popular, la música de big band y el jazz.",
    },
    usLang: {
      title: "Saxophone",
      description:
        "Conical musical instrument, of the family of woodwind instruments, made of brass, consisting of a mouthpiece with a simple reed. Invented in the early 1940s. The saxophone is commonly associated with popular music, big band music, and jazz.",
    },
    img: "https://i.ibb.co/mHkBMng/saxo.jpg",
    position: "",
    dataFil: DATA.filter((el) => el.category === "saxofones"),
  },
  {
    esLang: {
      title: "Flauta",
      description:
        "Instrumento bellamente construido que es fácil de tocar y diseñado músicos flautistas principiantes/intermedios. Las teclas garantizan durabilidad y una respuesta uniforme, amigable y fácil de tocar con una gran entonación.",
    },
    usLang: {
      title: "Flute",
      description:
        "Beautifully constructed instrument that is easy to play and designed for beginning/intermediate flutist musicians. The keys guarantee durability and an even, friendly, playable response with great intonation.",
    },
    img: "https://i.ibb.co/4fr2Ch0/flauta.jpg",
    position: "right",
    dataFil: DATA.filter((el) => el.category === "flautas"),
  },
  {
    esLang: {
      title: "Trompeta",
      description:
        "Trompetas con boquilla y válvulas de funcionamiento suave con cómodos botones. Diseñadas para que pueda desarrollar un buen sonido y una técnica adecuada, elegida por profesionales ya que satisfacen los estándares de calidad, ergonómicos y cómodas de tocar.",
    },
    usLang: {
      title: "Trumpet",
      description:
        "Mouthpiece trumpets and smooth-operating valves with convenient buttons. Designed so that you can develop a good sound and proper technique, chosen by professionals as they meet quality, ergonomic and comfortable playing standards.",
    },
    img: "https://i.ibb.co/PTC42SV/trumpet.jpg",
    position: "",
    dataFil: DATA.filter((el) => el.category === "trompeta"),
  },
  {
    esLang: {
      title: "Clarinete",
      description:
        "Clarinete es un instrumento confiable, fácil de tocar, muy bien centrado y duradero, brinda una respuesta excelente y una capacidad de interpretación fluida. El clarinete fue acogido rápidamente por las orquestas y su sonido se iba amoldando con la textura de los demás vientos de la orquesta.",
    },
    usLang: {
      title: "Clarinet",
      description:
        "The clarinet is a reliable, easy to play, very well centered and durable instrument, providing excellent response and fluid playability. The clarinet was quickly accepted by the orchestras and its sound was molding itself with the texture of the other wind instruments in the orchestra.",
    },
    img: "https://i.ibb.co/jLv2hZk/clarinet.jpg",
    position: "right",
    dataFil: DATA.filter((el) => el.category === "clarinetes"),
  },
  {
    esLang: {
      title: "Violín",
      description:
        "Violines de la familia de las cuerdas frotadas, es el más pequeño y agudo entre los de su clase. Fabricados en madera sólida de alta calidad, con sonidos suaves y muy cómodo al tacto, sin duda alguna, el instrumento perfecto para entrar al mundo de la música",
    },
    usLang: {
      title: "Violin",
      description:
        "Violins of the family of bowed strings, it is the smallest and most acute of its kind. Made of high-quality solid wood, with soft sounds and very comfortable to the touch, without a doubt, the perfect instrument to enter the world of music.",
    },
    img: "https://i.ibb.co/8XvFmvk/violinete.jpg",
    position: "",
    dataFil: DATA.filter((el) => el.category === "violin"),
  },
];
