import React, { useContext } from "react";
import { langContext } from "../../context/langContext";
import Slider from "../slider/Slider";

const ListProducts = ({ info }) => {
  const { locale } = useContext(langContext);
  const { title, titleEs, data } = info;

  const currentTitle = locale === "en-US" ? title : titleEs;

  return (
    <div className="products__container">
      <h2 className="products__title">{currentTitle}</h2>
      <Slider seeSlider={true} closeMenu={false} data={data} />
    </div>
  );
};

export default ListProducts;
