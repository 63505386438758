import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import "./hero.css";

const Hero = () => {
  return (
    <div className="hero">
      <div>
        <h2 className="hero__title">
          Experimented with sounds that will reach your heart
        </h2>
      </div>
      <Link to="/all-products" className="hero__btn">
        <FormattedMessage id="hero.btn" defaultMessage="See products" />
      </Link>
    </div>
  );
};

export default Hero;
