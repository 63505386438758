import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import IMG from "../../assets/img/contactImg.svg";
import LOADELR from "../../assets/img/loader.svg";

import "./contact.css";

const initialForm = {
  name: "",
  lastName: "",
  email: "",
};

const Contact = () => {
  const [loader, setloader] = useState(false);
  const [sendMessage, setsendMessage] = useState(false);
  const [willContact, setWillContact] = useState(false);
  const [form, setform] = useState(initialForm);

  const handleChange = (e) => {
    setform({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setloader(true);
    setTimeout(() => {
      setform(initialForm);
      setloader(false);
      setsendMessage(true);
      setTimeout(() => {
        setsendMessage(false);
        setWillContact(true);
        setTimeout(() => {
          setWillContact(false);
        }, 10000);
      }, 3000);
    }, 3000);
  };

  return (
    <section className="contact">
      <div className="contact__front">
        <div className="cf__tit">
          <h3 className="cd__title">
            <FormattedMessage id="contact.title" defaultMessage="Contact us" />
          </h3>
          <img className="cf__img" src={IMG} alt="" />
        </div>
        <form action="" className="contact__form" onSubmit={handleSubmit}>
          <div className="cf__name">
            <input
              type="text"
              name="name"
              placeholder="name"
              value={form.name}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last name"
              value={form.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <input
            type="text"
            name="email"
            value={form.email}
            onChange={handleChange}
            placeholder="correo"
            className="cf__emailInp"
          />
          <button className="contact__btn">
            <FormattedMessage id="contact.btn" defaultMessage="send" />
          </button>
          <div className="contact__response">
            <img
              src={LOADELR}
              alt="loader"
              className={`contact__loader ${loader ? "" : "none"}`}
            />
            <p className={`contact__message ${sendMessage ? "" : "none"}`}>
              <FormattedMessage
                id="contact.message"
                defaultMessage="Message send"
              />
            </p>
            <p className={`contact__message ${willContact ? "" : "none"}`}>
              <FormattedMessage
                id="contact.we"
                defaultMessage="We will contact you soon"
              />
            </p>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
