import { HashRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import ProductPage from "./pages/ProductPage";
import ProductsPage from "./pages/ProductsPage";
import { LangProvider } from "./context/langContext";

function App() {
  return (
    <LangProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:product" element={<ProductPage />} />
          <Route path="all-products" element={<ProductsPage />} />
          <Route path="*" element={<h1>Page not found</h1>} />
        </Routes>
        <Footer />
      </Router>
    </LangProvider>
  );
}

export default App;
