import React, { useContext } from "react";
import LOGO from "../../assets/img/albertLogo.png";
import { TbLanguageKatakana } from "react-icons/tb";
import { Link } from "react-router-dom";
import { langContext } from "../../context/langContext";

import "./header.css";

const Header = () => {
  const { setLang, changeLang } = useContext(langContext);
  return (
    <section className="head">
      <div className="head__container">
        <Link to="/">
          <img className="head__logo" src={LOGO} alt="" />
        </Link>
        <div className="head__icons">
          <div className="icons__item" onClick={() => setLang(changeLang)}>
            <TbLanguageKatakana />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
