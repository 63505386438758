import React from "react";
import Collection from "../components/collection/Collection";
import Hero from "../components/hero/Hero";
import Distributors from "../components/distributors/Distributors";
import Contact from "../components/contact/Contact";
import { collections } from "../helpers/collections";

const HomePage = () => {
  return (
    <div>
      <Hero />
      {collections.map((el, index) => (
        <Collection key={index} data={el} />
      ))}
      <Distributors />
      <Contact />
    </div>
  );
};

export default HomePage;
