import React, { useState, useRef, useEffect } from "react";
import "./productImages.css";

const ProductImages = ({ data }) => {
  const zoomRef = useRef();
  const [mainImg, setmainImg] = useState(data[0]);

  const handleZoom = () => {
    const divZoom = zoomRef.current;
    let device = window.screen.width < 800 ? "touchmove" : "mousemove";
    divZoom.addEventListener(device, (e) => {
      let imageZoom = e.currentTarget;
      let offsetX = 0;
      let offsetY = 0;
      let x = 0;
      let y = 0;
      e.offsetX ? (offsetX = e.offsetX) : (offsetX = e.touches[0].pageX);
      e.offsetY ? (offsetY = e.offsetY) : (offsetY = e.touches[0].pageY);
      x = (offsetX / imageZoom.offsetWidth) * 100;
      y = (offsetY / imageZoom.offsetHeight) * 100;
      imageZoom.style.backgroundPosition = x + "% " + y + "%";
    });
  };

  useEffect(() => {
    setmainImg(data[0]);
  }, [data]);

  useEffect(() => {
    handleZoom();
  }, []);

  return (
    <section className="productImgs">
      <div
        ref={zoomRef}
        className="productImgs__main"
        style={{ backgroundImage: `url(${mainImg})` }}
      >
        <img className="main__img" src={mainImg} alt="producto de albert" />
      </div>
      <div className="productImgs__thumbs">
        {data.map((el, index) => (
          <img
            key={index}
            className="thumbs__item"
            src={el}
            alt="producto de albert"
            onClick={() => setmainImg(data[index])}
          />
        ))}
      </div>
    </section>
  );
};

export default ProductImages;
