import React, { useState } from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import ProductCard from "../productCard/ProductCard";

import "./slider.css";

const Slider = ({ seeSlider, setSeeSlider, closeMenu, data }) => {
  const [currentSlide, setcurrentSlide] = useState(0);
  const numCards = data.length - 1;

  const nextSlide = () => {
    setcurrentSlide(currentSlide === numCards ? 0 : currentSlide + 1);
  };
  const prevSlide = () => {
    setcurrentSlide(currentSlide === 0 ? numCards : currentSlide - 1);
  };
  return (
    <section className={`slider  ${seeSlider ? "show" : ""}`}>
      <div className="slider__container">
        <div
          className="slider__list"
          style={{
            transform: `translateX(-${currentSlide * 270}px)`,
          }}
        >
          {data.map((el, index) => (
            <ProductCard key={index} data={el} />
          ))}
        </div>

        <div
          className={`slider__btn-Arrow btn-left ${
            numCards === 0 ? "none" : ""
          }`}
          onClick={prevSlide}
        >
          <MdArrowBackIos />
        </div>
        <div
          className={`slider__btn-Arrow btn-right ${
            numCards === 0 ? "none" : ""
          }`}
          onClick={nextSlide}
        >
          <MdArrowForwardIos />
        </div>
      </div>
      <button
        className={`slider__btn-close ${closeMenu ? "" : "none"}`}
        onClick={() => setSeeSlider(false)}
      >
        x
      </button>
    </section>
  );
};

export default Slider;
